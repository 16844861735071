.vower-yellow {
	color: #ffcc00;
}

.vower-blue {
	color: #0fafb0;
}

.vower-gold {
	color: #e4be20;
}

.white-text {
	color: #ffffff;
}
