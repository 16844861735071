.cart-title {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	flex-grow: 1;
}
.cart-list {
	flex-grow: 1;
}

.cart-item {
	display: flex;
	justify-content: space-between;
	column-gap: 1rem;

	padding: 1rem;
	margin-bottom: 1rem;

	background-color: var(--low-alpha);
	border-radius: 0.5rem;
}

.cart-image-container {
	flex: 1 1 0%;
}
.cart-image-container img {
	border-radius: 0.5rem;
	max-width: 10rem;
	max-height: 10rem;
}

.cart-item-middle {
	flex: 8 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.cart-item-middle a {
	font-size: 1.5rem;
}
.cart-item-price {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	column-gap: 5px;
	flex: 1 1;
	font-size: 2.5rem;
	text-align: right;
}
.cart-item-price span {
	display: none;
}
.cart-item-actions {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.cart-item-actions label {
	margin-bottom: 0;
	font-weight: 400;
}

.cart-checkout {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	row-gap: 0.5rem;

	width: 100%;
	margin-top: 2rem;
	padding: 1rem;
	background-color: var(--low-alpha);
	border-radius: 0.5rem;
}

.cart-checkout > div {
	display: flex;
	flex-wrap: wrap;
	column-gap: 5px;
}

/* Mobile Layout */
@media (max-width: 575.98px) {
	.cart-title {
		justify-content: center;
	}

	.cart .price-title {
		display: none;
	}

	.cart-item {
		flex-wrap: wrap;
		padding-bottom: 0.5rem;
	}

	.cart-image-container {
		display: flex;
		justify-content: center;
		flex: 1 1 100%;
		margin-right: 0;
		margin-bottom: 0.5rem;
	}

	.cart-item-middle {
		text-align: center;
	}
	.cart-item-actions {
		justify-content: center;
	}

	.cart-item-price {
		flex-basis: 100%;
		font-size: 1.75rem;
		text-align: center;
	}
	.cart-item-price span {
		display: initial;
	}
	.cart-item-price img {
		max-height: 1.75rem;
		margin-right: 0.5rem;
		padding-bottom: 3px;
	}

	.cart-checkout {
		justify-content: center;
		flex-wrap: wrap;
	}

	.cart-checkout > div {
		text-align: center;
		align-items: center;
		justify-content: center;
	}
}
