.sidebar-container {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 2rem;
}

.sidebar-container > div {
	position: relative;
}

.sidebar {
	height: 100%;
	padding: 3rem 3rem 0 2rem;
	border-right: 1px solid #6b6b6b;
}

.sidebar .form-check {
	margin-left: 1rem;
}

.sidebar .form-check:not(:last-child) {
	margin-bottom: 0.5rem;
}

.sidebar .form-check input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	height: 1rem;
	width: 1rem;
	margin-top: 0.2rem;
	border: 1px solid gray;
	border-radius: 5px;
	outline: var(--turquoise);
	background-color: transparent;
	transition: background-color 0.2s, border 0.2s;
}
.sidebar .form-check input[type="checkbox"]:checked {
	background-color: var(--turquoise);
	border: 1px solid transparent;
}

.sidebar .form-check-label {
	margin-left: 0.25rem;
}

.sidebar input[type="checkbox"],
.sidebar .form-check-label {
	cursor: pointer;
}

.sidebar select {
	color: var(--main-text) !important;
}

.sidebar-expander,
.sidebar-collapser {
	display: none;
	cursor: pointer;
}

.sidebar-expander {
	width: fit-content;
	top: 0.25rem;

	align-items: center;
	padding-left: 0.5rem;
	border-radius: 5px;

	background-color: var(--extra-alpha-dark);
}

.sidebar-collapser {
	position: absolute;
	top: 0.5rem;
	right: 0.75rem;
	font-size: 1.5rem;
}

@media (max-width: 767.98px) {
	.sidebar-container {
		display: block;
	}
	.sidebar {
		position: fixed;
		height: calc(100vh - 4rem);
		min-width: 250px;
		top: 2rem;
		left: -100vw;
		padding: 2rem;
		border: none;
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.8);
		z-index: 2;
		transition: left 0.5s;
		backdrop-filter: blur(4px);
	}
	html.dark .sidebar {
		background-color: rgba(0, 0, 0, 0.4);
		backdrop-filter: blur(10px);
	}
	.expanded .sidebar {
		left: 1.9rem;
	}

	html.dark .sidebar select {
		background-color: rgba(255, 255, 255, 0.1) !important;
	}

	.sidebar-shadow {
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		background-color: rgba(0, 0, 0, 0.75);
		z-index: 1;
		transition: opacity 0.2s;
	}

	.expanded .sidebar-shadow {
		width: 100vw;
		height: 100vh;
		opacity: 1;
	}

	.sidebar-collapser {
		display: block;
	}

	.sidebar-expander {
		display: flex;
	}
}
