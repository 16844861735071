.single-testimonial {
	display: flex;
	justify-content: center;
}
.single-testimonial > div {
	max-width: 1000px;
}

.testimonial-carousel {
	display: flex;
	justify-content: center;
	width: 100%;
}
.testimonial-carousel > div {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	max-width: 1140px;
	padding-left: 0;
	padding-right: 0;
}

@media (min-width: 576px) {
	.testimonial-carousel > div {
		padding-left: 3.5rem;
		padding-right: 3.5rem;
	}
}

.testimonial-main {
	display: flex;
	overflow-x: hidden;
}
.testimonial-body {
	font-size: clamp(1.5rem, 3vw, 2.25rem);
	min-width: 100%;
	transform: translateX(-100%);
}
.testimonial-body i {
	font-weight: 500;
}

.testimonial-carousel .arrow {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	color: var(--main-text);
	font-size: 2rem;
	cursor: pointer;
	transition: color 0.2s;
}
.testimonial-carousel .arrow:hover {
	color: var(--sunflower);
}

.testimonial-carousel .arrow-left {
	left: 0;
}
.testimonial-carousel .arrow-right {
	right: 0;
}

.testimonial-title {
	align-self: center;
	font-size: clamp(2.5rem, 5vw, 4rem);
}
.testimonial-source {
	font-weight: 200;
	color: gold;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
