.navbar {
	background-color: var(--nav-bg);
	font-weight: 600;
	z-index: 1;
	width: 100vw;
	backdrop-filter: blur(10px);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

.nav-link {
	color: var(--main-text) !important;
}

.nav-link:hover {
	color: var(--turquoise) !important;
}
html.dark .nav-link:hover {
	color: var(--sunflower) !important;
}

.navbar-toggler:hover {
	background-color: initial;
}

.navbar-nav {
	position: relative;
	width: 100%;
	text-align: center;
	justify-content: center;
	column-gap: 0.25rem;
}

.nav-half {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	column-gap: 0.5vw;
}

#left-brand {
	display: none;
}
#left-brand .navbar-brand {
	margin-left: calc(50vw - 1rem);
	transform: translateX(-50%);
}

.navbar-brand {
	margin-right: 0 !important;
}

.navbar .cart-count {
	position: absolute;
	top: 0px;
	right: -12px;
	width: 20px;
	height: 20px;
	border-radius: 50%;

	background-color: var(--turquoise);
	font-size: 0.8rem;
}

html.dark .cart-count {
	background-color: var(--plum);
}

@media (max-width: 767.98px) {
	.nav-half {
		flex-direction: column;
		align-items: initial;
		text-align: right;
	}
	#left-brand {
		display: block;
	}
	#center-brand {
		display: none;
	}
}
