.grid-container {
	display: grid;
	grid-template-areas:
		"header"
		"main"
		"footer";
	grid-template-rows: auto 1fr auto;
	min-height: 100vh;
	width: 100vw;
	overflow-x: hidden;
}

.main {
	grid-area: main;
	padding: 1rem;
	width: 100vw;
}

/* Homepage */
#home-screen .hero-container,
#home-screen .hero-container video {
	height: 455px;
	max-height: 100vh;
}
#home-screen .hero-container video {
	object-fit: cover;
}

#home-screen .row {
	margin-bottom: 1.5rem;
}

#home-screen img {
	border-radius: 10px;
	max-height: 400px;
	filter: var(--img-shadow-filter);
}

#home-screen .row-123 {
	margin-bottom: 4rem;
}

#home-screen p {
	font-size: 1.25rem;
}

#app-banner {
	margin-left: -1rem;
	margin-bottom: -1rem;
	padding-top: 4rem;
	padding-bottom: 3rem;
	width: 100vw;
	color: var(--charcoal);
	background-color: var(--sunflower);
	background-image: linear-gradient(116.82deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.25) 100%);
}
#app-banner p {
	font-size: 1.75rem;
}

/* Opportunity Screen */
.widget-block {
	background-color: var(--low-alpha);
	padding: 1rem;
	border-radius: 20px;
	word-wrap: break-word;
}

.widget-block ul {
	margin-bottom: 0;
}

@media (max-width: 767.98px) {
	.widget-block ul {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.widget-block ul li {
		max-width: 100%;
	}
}

/* How It Works Screen */
#how-it-works-screen .row img {
	border-radius: 25px;
	filter: brightness(50%);
	cursor: pointer;
	transition: filter 0.2s;
}
#how-it-works-screen .row img:hover {
	filter: brightness(75%);
}

#how-it-works-screen .position-relative h1 {
	color: var(--wool) !important;
	font-size: 2.5rem;
	width: 75%;
	position: absolute;
	left: 50%;
	top: 50%;
	pointer-events: none;
	transform: translate(-50%, -50%);
}

/* Buy Points Screen */
.points-package {
	position: relative;
	padding: 3rem 1rem;
	border: 2px solid var(--main-text);
	border-radius: 1rem;
	background-color: var(--low-alpha);
}

.points-package .best-deal {
	position: absolute;
	top: 1rem;
	left: 50%;
	transform: translateX(-50%);
	padding: 2px 5px;
	border-radius: 2px;

	background-color: var(--turquoise);
	color: var(--charcoal);
}

.points-package:hover {
	border-color: var(--sunflower);
}

.points-package .num-points {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	line-height: 1;
	font-size: 4rem;
}

.points-package .num-points img {
	max-height: 3rem;
}

.price {
	font-size: 2.5rem;
	color: var(--turquoise);
	font-weight: 400;
}

/* About Screen */
#about-screen h2 {
	font-size: 2.25rem;
}

#about-screen p {
	font-size: 1.25rem;
}

#about-screen .row img {
	border-radius: 10px;
	filter: var(--img-shadow-filter);
}

/* Terms / Privacy Screens */
#terms-screen h4,
#privacy-screen h4 {
	margin-top: 1rem;
}

/* Hero */
.hero-container {
	margin-left: -1rem;
	margin-top: -1rem;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	height: var(--hero-height);

	background-color: var(--main-bg);
}

.hero-container,
.hero-container img,
.hero-container video {
	position: relative;
	width: 100vw;
}

.hero-container img,
.hero-container video {
	filter: brightness(40%);
}

.hero-container > div {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	transform: translate(-50%, -50%);
	color: var(--wool);
	font-weight: bold;
	font-size: clamp(1.5rem, 5vw, 2.5rem);
	text-align: center;
}

/* Incentive Screen */
.carousel-container {
	max-width: 600px;
}
.carousel .slide {
	background-color: transparent !important;
}
.carousel .slide :is(img, video) {
	max-height: 400px;
	width: auto !important;
	max-width: 100%;
}

.control-prev::before {
	border-right-color: gray !important;
}
.control-next::before {
	border-left-color: gray !important;
}
.thumbs {
	padding: 0;
	display: flex;
	justify-content: center;
}

/* Profile */
.profile-image,
.profile-pic-input img {
	box-shadow: 0 0 15px 2px black;
	border: 2px solid #333;
	border-radius: 10px;
}
.social-links {
	display: flex;
	gap: 2rem;
}
.social-links svg {
	width: 40px;
	height: 40px;
}
