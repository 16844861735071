html {
	/* font-size: 62.5%; 16px * 62.5 = 10px = 1rem */
	box-sizing: border-box;

	/* Primary Color Palette */
	--turquoise: #25bebd;
	--sunflower: #e4be20;
	--plum: #48006d;
	--wool: #f1f1f1;
	--charcoal: #222;

	/* Secondary Color Palette */
	--powder-blue: #9be6e6;
	--flamingo: #ff76de;
	--lavender: #7a4d9f;
	--navy-blue: #1d0b53;

	/* Colors that will be changed by light / dark mode setting */
	/* Default colors are for light mode */
	--main-text: var(--charcoal);
	--bg: white;
	--nav-bg: var(--wool);
	--main-bg: var(--wool);
	--modal-border-color: #bbb;
	--icon-invert: invert(25%);
	--low-alpha: rgba(0, 0, 0, 0.05);
	--high-alpha: rgba(0, 0, 0, 0.1);
	--extra-alpha-dark: rgba(0, 0, 0, 0.1);

	/* Other Vars */
	--hero-height: 200px;
	--img-shadow-filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));

	background-color: var(--main-bg);
}

html.dark {
	--main-text: var(--wool);
	--bg: #140326;
	--nav-bg: transparent;
	--main-bg: var(--charcoal);
	--modal-border-color: #111;
	--icon-invert: none;
	--low-alpha: rgba(0, 0, 0, 0.25);
	--high-alpha: rgba(0, 0, 0, 0.4);
	--extra-alpha-dark: rgba(0, 0, 0, 0.6);
}
