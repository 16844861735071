#SAP-Cert {
	.sap-hero {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		min-height: 300px;
		width: 100vw;
		margin-top: -1rem;
		margin-left: -1rem;
		padding: 2rem 1rem;

		background: linear-gradient(to bottom, #0071c27d, transparent);

		h1 {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}

		img {
			height: 2.5rem;
		}
	}
	.total-rewards {
		display: flex;
		justify-content: center;
		column-gap: 0.5rem;

		text-align: center;
		background-color: transparent;
		font-size: min(10vw, 2.5rem);
		font-weight: 600;

		span {
			white-space: nowrap;
		}
	}
	.steps-container {
		position: relative;

		.steps {
			display: flex;
			flex-direction: row;
			gap: 1rem;
			overflow-x: scroll;
			width: 100vw;
			margin-left: -1rem;

			scroll-snap-type: x mandatory;
			scroll-behavior: smooth;

			.step {
				width: min(1200px, 90vw);
				margin: 0;
				border-radius: 1.5rem;
				background-color: white;
				box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
				flex-shrink: 0;

				scroll-snap-align: center;

				.row {
					height: 100%;

					[class*="col"] {
						display: flex;
						flex-direction: column;
						justify-content: center;

						h2 {
							font-size: 2rem;
							font-weight: 500;
							margin: 0;
						}
						p {
							margin: 0;
						}
						a {
							width: fit-content;
							margin-top: 0.5rem;
							padding: 0.25rem 0.75rem;
							border-radius: 0.5rem;
							color: white;
							background: linear-gradient(120deg, var(--turquoise), var(--sunflower));
							font-weight: 600;
							font-size: 1.5rem;
							text-decoration: none;
						}

						video,
						img {
							width: 100%;
							max-width: 400px;
							height: auto;
							max-height: 300px;
							border-radius: 0.5rem;
							align-self: center;
						}
					}
					.header {
						display: flex;
						flex-direction: column;

						* {
							white-space: nowrap;
						}

						.number {
							font-size: 2.5rem;
							font-weight: 600;
						}
						.points {
							font-size: min(8vw, 2.5rem);
						}
					}
				}
			}
		}
		.nav-buttons {
			button {
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
				border: none;
				font-size: 2rem;

				background-color: rgba(0, 0, 0, 0.1);
				color: black;
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
				backdrop-filter: blur(4px);

				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				z-index: 1;

				transition: visibility 0.2s, opacity 0.2s ease;

				&.hide {
					opacity: 0;
					visibility: hidden;
				}
			}
			.left {
				left: 0;
			}
			.right {
				right: 0;
			}
		}
	}

	.highlight {
		--bg-size: 200%;
		background: linear-gradient(45deg, var(--turquoise), var(--sunflower), var(--turquoise));
		background-size: var(--bg-size) auto;

		background-clip: text;
		-webkit-background-clip: text;
		-moz-background-clip: text;
		color: transparent;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		font-weight: bold;

		@media (prefers-reduced-motion: no-preference) {
			&.animate {
				animation: highlight-shift 3s infinite linear;
			}
			@keyframes highlight-shift {
				to {
					background-position: var(--bg-size) center;
				}
			}
		}
	}
	.sap-incentives-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;

		h2 {
			text-align: center;
		}
	}
}

html.dark #SAP-Cert .steps-container {
	.steps .step {
		background-color: black;
	}
	.nav-buttons button {
		background-color: rgba(255, 255, 255, 0.65);
		color: black;
		box-shadow: 0 0 5px #333;
	}
}
