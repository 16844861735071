.sliding-selector-wrapper {
	display: flex;
	gap: 0.25rem;
	position: relative;

	--wrapper-padding: 0.75rem;
	background-color: var(--low-alpha);
	padding: var(--wrapper-padding);
	border-radius: 10rem;
}

html.dark .sliding-selector-wrapper {
	background-color: var(--high-alpha);
}

.sliding-selector {
	background-color: transparent;
	color: var(--main-text);

	padding: 0.25rem 1rem;
	border: none;
	border-radius: inherit;

	z-index: 1;
	cursor: default !important;
}
.sliding-selector:not(.selected) {
	cursor: pointer !important;
}

.slider {
	background-color: var(--high-alpha);

	width: 100px;
	height: calc(100% - 2 * var(--wrapper-padding));
	position: absolute;
	border-radius: inherit;

	transition: 0.4s;
}

html.dark .slider {
	background-color: rgba(255, 255, 255, 0.15);
}
