#__react-alert__ {
	--exit-button-color: rgba(0, 0, 0, 0.1);
}
html.dark #__react-alert__ {
	--exit-button-color: rgba(0, 0, 0, 0.4);
}

#__react-alert__ .alert {
	position: relative;
	padding: 2rem;
	border-radius: 10px;
	background-color: var(--main-bg);
	box-shadow: 0 0 10px black;
}

#__react-alert__ .alert button.exit {
	position: absolute;
	top: 0.25rem;
	right: 0.25rem;

	display: flex;
	padding: 0;
	border: none;
	border-radius: 5px;
	background-color: var(--exit-button-color);
	color: var(--main-text);
	font-size: 2rem;
	transition: background-color 0.2s;
}

#__react-alert__ .alert button.exit:hover {
	--exit-button-color: rgba(0, 0, 0, 0.15);
}
html.dark #__react-alert__ .alert button.exit:hover {
	--exit-button-color: rgba(0, 0, 0, 0.75);
}

#__react-alert__ .alert.addToCart {
	font-size: 1.5rem;
}

#__react-alert__ .alert.justRegistered {
	transform: translateY(-50%);
	max-width: min(80%, 750px);
	margin: auto !important;
}
