.listing {
	width: 100%;
}

.listing .listing-image {
	width: 100%;
	margin-bottom: 0.5rem;
	border-radius: 10px;
	background-color: var(--main-bg);
	filter: var(--img-shadow-filter);
}

/* For opportunities */
.listing .listing-image {
	position: relative;
}

.listing .listing-image img {
	border-radius: inherit;
}
.listing .listing-image .vower-logo {
	max-width: 50%;
	height: auto;
	transform: translate(50%, 50%);
}

.listing .listing-image div {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: fit-content;
	max-width: calc(100% - 1rem);

	background-color: rgba(0, 0, 0, 0.5);
	color: var(--wool);
	backdrop-filter: blur(2.5px);
	padding: 5px 10px;
	border-radius: 5px;

	text-align: center;
}

.listing .listing-image .company-name {
	top: 0.5rem;
}

.listing .points {
	display: flex;
	align-items: center;
	font-size: 1.5rem;
}
.listing .points img {
	max-height: 1.75rem;
	margin-right: 0.5rem;
}
.listing .points span {
	color: var(--sunflower);
	font-weight: 600;
	margin-right: 0.35rem;
}

@media (min-width: 425px) {
	@media (max-width: 575.98px) {
		.xs-double.row-cols-1 > * {
			flex: 0 0 50% !important;
			max-width: 50% !important;
		}
	}
}

@media (max-width: 767.98px) {
	:is(#opportunities-screen, #incentives-screen) .container {
		max-width: 100%;
	}
}
