.manage-modal {
	max-width: 750px;
}

.manage-modal .modal-footer {
	border-top: 1px solid var(--modal-border-color);
	justify-content: space-between;
}

.manage-modal .modal-content {
	background-color: var(--main-bg);
	color: var(--main-text);
}

.manage-modal h2 {
	margin-bottom: 1rem;
}

.manage-modal :is(input, select, textarea):not(.react-datetime-picker *) {
	background-color: var(--low-alpha);
	color: var(--main-text);
	border-width: 0;
}

.manage-modal .was-validated :is(input, select, textarea):not(.react-datetime-picker *) {
	border-width: 1px;
}

.manage-modal .input-group {
	flex-wrap: nowrap;
}

.manage-modal .input-group-text {
	background-color: var(--low-alpha);
	color: var(--main-text);
	border: 1px solid #aaa;
}
html.dark .manage-modal .input-group-text {
	border: 1px solid #555;
}

.manage-modal .block {
	border-radius: 5px;
	padding: 0.5rem;
	background-color: var(--low-alpha);
	margin-bottom: 1rem;
}

/* Datetime picker */
.react-datetime-picker {
	display: block;
}

.react-datetime-picker__wrapper {
	background-color: var(--low-alpha);
	padding: 0.5rem;
	border: none;
	border-radius: 0.5rem;
}

.react-datetime-picker button {
	background-color: transparent !important;
	border: none !important;
}

.react-datetime-picker svg {
	stroke: var(--main-text) !important;
	transition: stroke 0.2s;
}
.react-datetime-picker .react-datetime-picker__clear-button__icon:hover {
	stroke: rgb(255, 100, 100) !important;
}
.react-datetime-picker .react-datetime-picker__calendar-button__icon:hover {
	stroke: rgb(100, 100, 100) !important;
}

.react-datetime-picker input,
.react-datetime-picker select {
	color: var(--main-text);
}

.react-calendar {
	background-color: var(--main-bg);
	color: var(--main-text);
	border-color: var(--modal-border-color);
}
.react-calendar__navigation button {
	color: var(--main-text);
}
.react-calendar__tile {
	color: var(--main-text);
	transition: color 0.1s;
}
.react-calendar__tile:hover {
	color: rgb(134, 134, 134);
}
.react-calendar__tile--active {
	color: rgb(47, 204, 47) !important;
	font-weight: bold;
}
.react-calendar__tile--now {
	color: rgb(16, 159, 255);
}
.react-calendar__month-view__days__day--weekend {
	color: rgb(255, 85, 85);
}
