body {
	font-family: "Poppins", sans-serif;
	color: var(--main-text);
	font-weight: 300;
	overflow-x: hidden;
}

/* body::-webkit-scrollbar {
	width: 0.15em;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--sunflower);
} */

#root {
	background-color: var(--bg);
}

a,
.fake-anchor {
	color: var(--turquoise);
	font-weight: 500;
	cursor: pointer;
}

html.dark :is(a, .fake-anchor) {
	color: var(--sunflower);
}

:is(a, .fake-anchor):hover {
	color: #157e7e;
	text-decoration: none;
}

html.dark :is(a, .fake-anchor):hover {
	color: #a58a1a;
}

a *,
.fake-anchor * {
	color: var(--main-text);
}
:is(a, .fake-anchor) * {
	color: var(--main-text);
}

b {
	font-weight: bold;
}

.my-input,
.my-select {
	padding: 0.25rem;
	border: none;
	border-radius: 0.25rem;
}

.my-input,
.my-select,
:is(input, select).form-control {
	background-color: var(--high-alpha) !important;
	color: var(--main-text) !important;
}

.my-input,
.my-select,
:is(input, select).form-control:disabled {
	color: rgb(140, 140, 140) !important;
}

form:not(.was-validated) :is(input, select).form-control {
	border: none;
}

.hidden {
	visibility: hidden;
	height: 0px;
	padding: 0;
	border: none;
}

/* Bootstrap Switch */
.custom-control-input:checked ~ .custom-control-label::before {
	border-color: var(--turquoise);
	background-color: var(--turquoise);
}
html.dark .custom-control-input:checked ~ .custom-control-label::before {
	border-color: var(--sunflower);
	background-color: var(--sunflower);
}

/* Buttons */
.btn {
	font-weight: 500;
}

:is(.btn-plum, .btn-minor),
:is(.btn-plum, .btn-minor):disabled {
	background-color: var(--plum);
	color: var(--wool);
	border: 1px solid var(--plum);
	transition: filter 0.2s;
}

:is(.btn-turquoise, .btn-main),
:is(.btn-turquoise, .btn-main):disabled {
	background-color: var(--turquoise);
	color: var(--charcoal);
	border: 1px solid var(--turquoise);
	transition: filter 0.2s;
}

:is(.btn-sunflower, html.dark .btn-main),
:is(.btn-sunflower, html.dark .btn-main):disabled {
	background-color: var(--sunflower);
	color: var(--charcoal);
	border: 1px solid var(--sunflower);
	transition: filter 0.2s;
}

:is(.btn-plum, .btn-minor):not(:disabled):hover {
	color: var(--wool);
	filter: brightness(90%);
}

:is(.btn-turquoise, html.dark .btn-main):not(:disabled):hover {
	color: var(--charcoal);
	filter: brightness(80%);
}

:is(.btn-sunflower, .btn-main):not(:disabled):hover {
	color: var(--charcoal);
	filter: brightness(80%);
}

button.wrapper {
	padding: 0;
	border: none;
	background-color: transparent;
}

/* Tables */
.table {
	color: var(--main-text);
}

.table thead {
	border-bottom: 1px solid var(--main-text);
	background-color: var(--low-alpha);
}

.table :is(tbody td, thead th) {
	border: none;
}
.table :is(tbody td, thead th):not(:last-child) {
	border-right: 1px dashed var(--low-alpha);
}

.table thead th:first-child {
	border-top-left-radius: 1rem;
}
.table thead th:last-child {
	border-top-right-radius: 1rem;
}
.table tbody tr:last-child td:first-child {
	border-bottom-left-radius: 1rem;
}
.table tbody tr:last-child td:last-child {
	border-bottom-right-radius: 1rem;
}

.table tbody > tr:nth-child(even) {
	background-color: var(--low-alpha);
}

.table .shorten-description {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	padding-bottom: 0;
	margin-bottom: 0.75rem;
}

/* Circular Progress Bar */
.CircularProgressbar {
	max-width: 150px;
}
.CircularProgressbar .CircularProgressbar-trail {
	stroke: rgba(0, 0, 0, 0.5);
}
.CircularProgressbar .CircularProgressbar-path {
	stroke: var(--sunflower);
	stroke-linecap: square;
}
.CircularProgressbar .CircularProgressbar-text {
	stroke: var(--main-text);
	font-size: 1rem;
}

/* Other */
img.circle {
	border-radius: 50% !important;
}

.square-img-box,
.four-three-img-box {
	position: relative;
}
:is(.square-img-box, .four-three-img-box)::after {
	display: block;
	content: "";
}
:is(.square-img-box, .four-three-img-box) :is(img, svg) {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.square-img-box::after {
	padding-bottom: 100%;
}

.four-three-img-box::after {
	padding-bottom: 133%;
}

.divider {
	border-top: 1px solid var(--sunflower);
	margin: 3rem 0rem;
}

/* Medium Breakpoint */
@media (min-width: 768px) {
	html {
		--hero-height: 300px;
	}
	.position-md-absolute {
		position: absolute !important;
	}
	.position-md-relative {
		position: relative !important;
	}
}

/* How it works Org */

.how-it-works-p {
	font-size: 1.3rem;
}
