.manage-opportunity-status button {
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
	padding: 2px 13px 2px 5px;
	font-size: 0.9rem;
}

.manage-opportunity-status .static-status {
	display: flex;
	align-items: center;

	width: fit-content;
	padding: 2px 13px 2px 5px;
	border-radius: 5px;
	background-color: var(--low-alpha);
}
