@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	src: url("../../fonts/Inter/Inter-Regular.ttf");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	src: url("../../fonts/Inter/Inter-SemiBold.ttf");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	src: url("../../fonts/Inter/Inter-Bold.ttf");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 800;
	src: url("../../fonts/Inter/Inter-ExtraBold.ttf");
}
