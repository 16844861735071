.social-icon {
	transition: fill 0.25s;
	height: min(5vw, 25px);
	width: min(5vw, 25px);
	filter: brightness(0) invert(1);
}

.social-icon:hover {
	filter: invert(0.5);
}

.instagram-icon:hover {
	fill: #e1306c;
}

.twitter-icon:hover {
	fill: #1da1f2;
}

.facebook-icon:hover {
	fill: #1778f2;
}

.medium-icon:hover {
	fill: #51a652;
}

.linkedin-icon:hover {
	fill: #3277b5;
}

.youtube-icon:hover {
	fill: red;
}
