#signin-page input,
#register-screen input,
#contactus-page input {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid gray;
	border-radius: 0;
	color: var(--main-text);
}

#register-screen textarea,
#contactus-page textarea {
	background-color: transparent;
	min-height: 3rem;
	padding: 0.75rem;
	border: 1px solid gray;
	color: var(--main-text);
}

/* Profile Pic */
.profile-pic-input input[type="file"] {
	display: none;
}
.profile-pic-input .form-file-label {
	cursor: pointer;
	text-align: center;
}
.profile-pic-input .form-file-label img {
	max-width: 80%;
	max-height: 300px;
}

#register-screen .profile-pic-input .form-file-label img {
	min-width: 120px;
	min-height: 120px;
}

/* Add/Remove Buttons */
.add {
	display: flex;
	width: fit-content;
	padding: 0.5rem;
	border: none;
	border-radius: 5px;

	background-color: var(--green);
	color: var(--charcoal);
	cursor: pointer;
	transition: background-color 0.2s;
}
.add:hover {
	background-color: rgb(0, 215, 0);
}
.add svg {
	margin-left: 0.25rem;
	height: 1.5rem;
	width: auto;
}

.remove {
	height: fit-content;
	border-radius: 5px;

	background-color: white !important;
	cursor: pointer;
}
.remove svg {
	fill: #dc3545;
	height: 2rem;
	width: auto;
	transition: fill 0.2s;
}
.remove svg:hover {
	fill: #c82333;
}

/* File Upload */
.btn-danger.clear {
	padding: 0px 5px;
}
.file-list {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}
.file-list > div {
	position: relative;
	padding: 0.5rem 0 0 0.5rem;
}
.file-list .remove {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.file-upload input[type="file"] {
	display: none;
}
.file-upload :is(img, video) {
	max-width: 200px;
	max-height: 200px;
	border-radius: 5px;
}
.file-upload label {
	width: 100%;
	margin-bottom: 0;
}

#signin-page label {
	color: var(--main-text) !important;
}

.separator {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	column-gap: 1rem;
	margin: 0.75rem 0;
}

.separator hr {
	width: 100%;
	border: 1px solid var(--main-text);
}

.was-validated .form-control:invalid {
	border-bottom: 1px solid #dc3545 !important;
}
.was-validated .form-control:valid {
	border-bottom: 1px solid #28a745 !important;
}

.firebaseui-idp-google {
	background-color: white !important;
	transition: background-color 0.2s;
}
.firebaseui-idp-google:hover {
	background-color: #e6e6e6 !important;
}

.firebaseui-idp-facebook {
	background-color: #3d77f2 !important;
	transition: background-color 0.2s;
}
.firebaseui-idp-facebook:hover {
	background-color: #1a6cf0 !important;
}
